import camelcaseKeys from "camelcase-keys";
import { LocationQuery } from "vue-router";

import { TargetFilters } from "@/api/target/typedefs";
import { isValidRangeFilterFormat } from "@/lib/filters";

export function buildTargetFiltersFromQueryParams(
  queryParams: LocationQuery
): TargetFilters {
  const camelCasedQueryParams: Record<string, string> =
    camelcaseKeys(queryParams);
  const result: TargetFilters = {
    createdBy: camelCasedQueryParams.createdBy,
    name: camelCasedQueryParams.name,
    id: camelCasedQueryParams.id,
  };
  if (!isValidRangeFilterFormat(camelCasedQueryParams.id)) {
    result.id = undefined;
  }
  return result;
}
