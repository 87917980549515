import { computed, Ref } from "vue";

import { TargetType } from "@/api/target/typedefs";
import { usePermissions } from "@/composables/usePermissions";
import { getProductLine } from "@/lib/productLine";

interface UseTargetsPermissionsOptions {
  productLine: Ref<number>;
  targetType: Ref<TargetType>;
}

export function useTargetsPermissions({
  productLine: productLineId,
  targetType,
}: UseTargetsPermissionsOptions) {
  const { hasPushMessagePermission, hasInAppPermission } = usePermissions();
  const productLine = computed(() => getProductLine(productLineId.value));

  const hasAddTargetPermission = computed(() => {
    if (targetType.value === "pushnotifications") {
      return hasPushMessagePermission("add_messagetarget", productLine.value);
    } else {
      return hasInAppPermission("add_inapptarget", productLine.value);
    }
  });

  return {
    hasAddTargetPermission,
  };
}
