import { User, UserGuard } from "@/api/users";
import { httpClient, throwIfBadResponse } from "@/lib/http";
import { cast } from "@/lib/typing";

function convertUser(data: unknown): User {
  return cast(UserGuard, data);
}

function convertUsers(data: unknown): User[] {
  if (!Array.isArray(data)) return [];
  return data.map((user) => convertUser(user));
}

export async function fetchPushMessageTargetUsers(
  productLine: number
): Promise<User[]> {
  const response = await httpClient.get(
    `/api/pushnotifications/product-lines/${productLine}/message-target-users/`
  );

  await throwIfBadResponse(response);

  const data = await response.json();

  return convertUsers(data);
}
