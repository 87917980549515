import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row q-col-gutter-md q-gutter-y-sm" }
const _hoisted_2 = { class: "col-12 col-sm-4 q-gutter-y-md" }
const _hoisted_3 = { class: "col-12 col-sm-4 q-gutter-y-xl" }
const _hoisted_4 = { class: "col-12 col-sm-4 q-gutter-y-md" }

import { debounce } from "lodash";
import QForm from 'quasar/src/components/form/QForm.js';;
import { computed, ref, Ref, watch } from "vue";

import { TargetFilters } from "@/api/target/typedefs";
import ListFilters from "@/components/shared/list/ListFilters.vue";
import { isValidRangeFilterFormat } from "@/lib/filters";


export default /*@__PURE__*/_defineComponent({
  __name: 'TargetsFilters',
  props: {
    filters: {},
    createdByOptions: {}
  },
  emits: ["filtersChange"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;
const filtersForm: Ref<QForm | null> = ref(null);

const name: Ref<string | undefined> = ref(props.filters.name);
const messageId: Ref<string | undefined> = ref(props.filters.id);
const createdBy: Ref<string | undefined> = ref(props.filters.createdBy);

const onFiltersChange = debounce(async () => {
  const isValid = await filtersForm.value?.validate();
  if (!isValid) return;

  emit("filtersChange", {
    name: name.value,
    id: messageId.value,
    createdBy: createdBy.value,
  });
}, 500);

const clearAllFilters = () => {
  name.value = undefined;
  messageId.value = undefined;
  createdBy.value = undefined;
};

const isClearButtonVisible = computed(() => {
  return (
    Object.values(props.filters).find(
      (val) => val !== null && val !== "" && val !== undefined
    ) !== undefined
  );
});

watch([name, messageId, createdBy], () => onFiltersChange());

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createBlock(ListFilters, { title: "Filter Targets" }, {
    "title-bar": _withCtx(() => [
      (isClearButtonVisible.value)
        ? (_openBlock(), _createBlock(_component_q_btn, {
            key: 0,
            outline: "",
            rounded: "",
            color: "primary",
            "icon-right": "clear",
            label: "Clear all filters",
            "data-test": "target.filters.clear",
            "no-caps": "",
            onClick: clearAllFilters
          }))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_unref(QForm), {
          ref_key: "filtersForm",
          ref: filtersForm
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_q_input, {
                  modelValue: name.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((name).value = $event)),
                  dense: "",
                  filled: "",
                  label: "Name",
                  "data-test": "target.filters.name"
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_q_select, {
                  modelValue: createdBy.value,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((createdBy).value = $event)),
                  options: _ctx.createdByOptions,
                  dense: "",
                  filled: "",
                  "data-test": "target.filters.createdby",
                  label: "Created by",
                  clearable: ""
                }, null, 8, ["modelValue", "options"])
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_q_input, {
                  modelValue: messageId.value,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((messageId).value = $event)),
                  dense: "",
                  filled: "",
                  label: "ID",
                  placeholder: "i.e. 1,2,5-10",
                  "data-test": "target.filters.id",
                  rules: [
                (val) =>
                  val === null ||
                  val === '' ||
                  val === undefined ||
                  _unref(isValidRangeFilterFormat)(val) ||
                  'ID is filter format invalid, must be comma separated list of ids, i.e. 1,2,5-10',
              ]
                }, null, 8, ["modelValue", "rules"])
              ])
            ])
          ]),
          _: 1
        }, 512)
      ])
    ]),
    _: 1
  }))
}
}

})