import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { computed, PropType } from "vue";

import { TargetType } from "@/api/target/typedefs";
import ListHeader from "@/components/shared/list/ListHeader.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'TargetsHeader',
  props: {
  targetType: {
    type: String as PropType<TargetType>,
    required: true,
  },
  addButtonVisible: {
    type: Boolean,
    required: false,
    default: true,
  },
},
  emits: { addNewTarget: null },
  setup(__props) {

const props = __props;


const shortTitle = computed(() => {
  return props.targetType === "pushnotifications" ? "PN" : "IA";
});

const title = computed(() => {
  let titleItem: string = props.targetType;
  if (props.targetType === "pushnotifications") {
    titleItem = "Push Notifications";
  } else if (props.targetType === "inapps") {
    titleItem = "In Apps";
  }
  return `Target list - ${titleItem}`;
});

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createBlock(ListHeader, { title: title.value }, {
    default: _withCtx(() => [
      (__props.addButtonVisible)
        ? (_openBlock(), _createBlock(_component_q_btn, {
            key: 0,
            icon: "add",
            color: "primary",
            "data-test": "targetlist.header.button.add",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('addNewTarget')))
          }, {
            default: _withCtx(() => [
              _createTextVNode("Create new " + _toDisplayString(shortTitle.value) + " target", 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title"]))
}
}

})