import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "targets q-pa-md" }

import { computed, toRefs } from "vue";
import { onBeforeRouteUpdate } from "vue-router";

import { TargetType } from "@/api/target/typedefs";
import { useOpenTargetModal } from "@/components/shared/target/dialog/useOpenTargetModal";
import TargetsFilters from "@/components/targets/TargetsFilters.vue";
import TargetsHeader from "@/components/targets/TargetsHeader.vue";
import TargetList from "@/components/targets/TargetsList.vue";
import { DataState } from "@/composables/typedefs";
import { mapParamsRouteGuard } from "@/router/mapParamsRouteGuard";
import { useTargets } from "@/views/targets/useTargets";


export default /*@__PURE__*/_defineComponent({
  __name: 'Targets',
  props: {
    targetType: {},
    product: {},
    productLine: {}
  },
  setup(__props: any) {

onBeforeRouteUpdate(mapParamsRouteGuard);

const props = __props;

const { openTargetModal } = useOpenTargetModal();

const {
  targets,
  state,
  filters,
  pagination,
  handleTargetSelect,
  handleAddNewTarget,
  onFiltersChange,
  onPaginationChange,
  hasAddTargetPermission,
  createdByOptions,
} = useTargets({
  ...toRefs(props),
  openTargetModal: openTargetModal,
});

const isLoading = computed(() => state.value === DataState.LOADING);
const isError = computed(() => state.value === DataState.ERROR);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(TargetsHeader, {
      "target-type": props.targetType,
      "add-button-visible": _unref(hasAddTargetPermission),
      onAddNewTarget: _unref(handleAddNewTarget)
    }, null, 8, ["target-type", "add-button-visible", "onAddNewTarget"]),
    _createVNode(TargetsFilters, {
      filters: _unref(filters),
      "created-by-options": _unref(createdByOptions),
      onFiltersChange: _unref(onFiltersChange)
    }, null, 8, ["filters", "created-by-options", "onFiltersChange"]),
    _createVNode(TargetList, {
      targets: _unref(targets),
      pagination: _unref(pagination),
      "target-type": _ctx.targetType,
      "is-loading": isLoading.value,
      "is-error": isError.value,
      onRowClick: _unref(handleTargetSelect),
      onPaginationChange: _unref(onPaginationChange)
    }, null, 8, ["targets", "pagination", "target-type", "is-loading", "is-error", "onRowClick", "onPaginationChange"])
  ]))
}
}

})