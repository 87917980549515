import camelcaseKeys from "camelcase-keys";
import * as t from "io-ts";

import {
  RawTargetResponse,
  RawTargetResponseGuard,
  Target,
  TargetGuard,
  TargetInAppGuard,
  TargetType,
} from "@/api/target/typedefs";
import { httpClient } from "@/lib/http";
import { cast } from "@/lib/typing";

function convertTargets(targetData: unknown): Target[] {
  const validTargetData: RawTargetResponse = cast(
    RawTargetResponseGuard,
    targetData
  );
  // TODO(PNS-1842): drop this workaround after fixing affected users serialization
  for (const singleTargetData of validTargetData) {
    singleTargetData["affected_users"] =
      singleTargetData["affected_users_number"];
  }
  const camelCasedData = camelcaseKeys(validTargetData);
  try {
    return cast(t.array(TargetInAppGuard), camelCasedData);
  } catch {
    return cast(t.array(TargetGuard), camelCasedData);
  }
}

function filterUndefinedValues(
  queryParams: Record<string, string | undefined>
): Record<string, string> {
  const definedValues: Record<string, string> = {};
  for (const [key, value] of Object.entries(queryParams)) {
    if (value) {
      definedValues[key] = value;
    }
  }
  return definedValues;
}

export async function fetchTargets(
  targetType: TargetType,
  productLineId: number,
  queryParams: Record<string, string | undefined>
): Promise<{ data: Target[]; count: number }> {
  const targetPrefix = targetType === "pushnotifications" ? "message" : "inapp";
  const activeQueryParams: Record<string, string> =
    filterUndefinedValues(queryParams);
  const url = `/api/${targetType}/product-lines/${productLineId}/${targetPrefix}-targets/`;
  const response = await httpClient.get(
    url,
    Object.keys(activeQueryParams).length === 0 ? undefined : activeQueryParams
  );
  const responseData = await response.json();
  const convertedData = convertTargets(responseData.results ?? responseData);
  return {
    data: convertedData,
    count: responseData.count ?? convertedData.length,
  };
}
